import React from 'react'
import Box from '@material-ui/core/Box'
import useRawAnalyticsData from '../hooks/use-raw-analytics-data'
import { StatsContext } from './stats-tab'
import { makeFilter } from './rest-data-view'
import { CircularProgress, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button'

const mapRawDataDetails = (response) => {
  const { rows } = response

  if (rows && rows.length > 0) {
    const filteredResponse = rows
      .map(respData => {
        return {
          id: respData[0],
          name: respData[0],
          event: respData[1],
          views: Number(respData[3])
        }
      })

    return filteredResponse
  }
  return null
}

const SidePanelDetails = ({
  metrics,
  selectedPartner,
  selectedDetail,
  setOpen
}) => {
  const newFilters = () => makeFilter([
    { category: selectedPartner, sep: ';' },
    { event: selectedDetail, sep: ',' }
  ])

  const rawData = useRawAnalyticsData({
    filters: newFilters(),
    metrics: metrics,
    dimensions: 'ga:eventAction,ga:eventLabel,ga:eventCategory'
  })

  React.useEffect(() => {
    if (selectedDetail) {
      rawData?.execute()
    }
  }, [selectedDetail])

  const rawDataMapped = rawData?.data && mapRawDataDetails(rawData?.data)

  return <Box>
    {
      rawData.loading
        ? <Box
          display='flex'
          justifyContent='center'
        >
          <CircularProgress size='small' />
        </Box>
        : <Box>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='subtitle1'>{selectedDetail}</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Actie</TableCell>
                  <TableCell align='right'>Views</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                rawDataMapped && rawDataMapped.map(dataRow => {
                  return <TableRow>
                      <TableCell>{dataRow.event}</TableCell>
                      <TableCell align='right'>{dataRow.views}</TableCell>
                    </TableRow>
                })
              }
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display='flex'
            justifyContent='center'
          >
            <Button
              sx={{
                mt: 2
              }}
              onClick={() => setOpen(false)}
              size='small'
              variant='outlined'
              endIcon={<CloseIcon />}
            >
              Details sluiten
            </Button>
          </Box>
      </Box>
    }
  </Box>
}

export default SidePanelDetails