import React from 'react'
import DataTable from './data-table'
import { subDays } from 'date-fns'

export const StatsContext = React.createContext()

const StatsTab = ({ viewId, siteName, links, getAllLinks }) => {
  const [currentDate, setCurrentDate] = React.useState([
    subDays(new Date(), 7),
    new Date()
  ])

  if (!viewId) {
    return null
  }

  return <StatsContext.Provider
    value={{
      viewId,
      currentDate,
      setCurrentDate,
      siteName,
      links,
      getAllLinks
    }}
  >
    <DataTable
      metrics='ga:totalEvents'
      dimensions='ga:eventCategory'
    />
  </StatsContext.Provider>

}

export default StatsTab
