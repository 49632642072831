import React from 'react'
import { makeStyles } from '@material-ui/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { StatsContext } from './stats-tab'

const DateOptions = [
  {
    label: 'Vandaag',
    value: 'today'
  },
  {
    label: 'Gisteren',
    value: 'yesterday'
  },
  {
    label: 'Afgelopen week',
    value: '7daysAgo'
  },
  {
    label: 'Afgelopen maand',
    value: '30daysAgo'
  },
  {
    label: 'Altijd',
    value: '2019-01-01'
  }
]

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 220
  }
}))

const DateRange = () => {
  const { currentDate, setCurrentDate } = React.useContext(StatsContext)

  const classes = useStyles()

  const handleChange = (selectEvent) => setCurrentDate(selectEvent.target.value)

  return <FormControl variant='outlined' className={classes.formControl} size='small'>
    <InputLabel id='select-date-label'>Periode</InputLabel>
    <Select
      labelId='select-date-label'
      id='date-select'
      value={currentDate}
      onChange={handleChange}
      label='Periode'
    >
      {
        DateOptions.map(option => <MenuItem
          key={option.value}
          value={option.value}
        >{option.label}</MenuItem>)
      }
    </Select>
  </FormControl>
}

export default DateRange