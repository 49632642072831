import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'

// import DateRange from './date-range'
import StaticDateRange from './static-date-range'
import { convertDataNameToNiceName } from '../helpers/name'

import RestDataView from './rest-data-view'
import CompanyProfile from './company-profile'
import CustomStatsTab from './custom-stats-tab'

const HeadingDiv = styled(Typography)`
`

const MarginDiv = styled(Divider)`
  margin-bottom: 10px;
  margin-top: 20px;
`


const TabPanel = ({ children, active }) => {
  if (!active) {
    return null
  }

  return <Box mt={2}>
    { children }
  </Box>
}


const Details = ({ partner: selectedPartner, tabs }) => {
  const [activeTab, setActiveTab] = React.useState(0)

  return <>
    <Box
      display='flex'
      justifyContent='space-between'
    >
      <HeadingDiv variant='h6'>
        {
          convertDataNameToNiceName(selectedPartner) || selectedPartner
        }
      </HeadingDiv>
      <StaticDateRange />
    </Box>

    <MarginDiv />
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          indicatorColor='primary'
          textColor='primary'
        >
          {/* <Tab label='Bedrijfsprofiel' />
          <Tab label='Overige paginas' /> */}
          {
            tabs.map((tab, index) => {
              return <Tab key={index} label={tab.title} />
            })
          }
        </Tabs>

        {/* <TabPanel
          active={activeTab === 0}
        >
          <CompanyProfile
            selectedPartner={selectedPartner}
          />
        </TabPanel>
        <TabPanel
          active={activeTab === 1}
        >
          <RestDataView
            selectedPartner={selectedPartner}
          />
        </TabPanel> */}
        {
          tabs.map((tab, index) => {
            return <TabPanel
              active={activeTab === index}
            >
              <CustomStatsTab
                {...tab}
                selectedPartner={selectedPartner}
                key={index}
              />
            </TabPanel>
          })
        }
      </Grid>
    </Grid>
  </>
}

Details.propTypes = {
  partner: PropTypes.string.isRequired
}

export default Details
