import React from 'react'
import Grid from '@material-ui/core/Grid'
import Details from './details'
import { StatsContext } from './stats-tab'
import { subDays } from 'date-fns'

const getAllLinks = async () => {
  const allLinkResponse = await fetch('https://www.mijnhiswarecron.nl/myhiswa/endpoint/my/analytics-link')
  const allLinkJson = await allLinkResponse.json()

  return allLinkJson.data
}

const SingleDetailView = ({ partner }) => {
  const [currentDate, setCurrentDate] = React.useState([
    subDays(new Date(), 7),
    new Date()
  ])

  const [links, setLinks] = React.useState([])

  const fetchLinks = async () => {
    const links = await getAllLinks()
    console.log(links)
    setLinks(links)
  }

  React.useEffect(() => {
    fetchLinks()
  }, [])

  return <Grid container spacing={3}>
    <Grid item xs={12}>
      <StatsContext.Provider
        value={{
          viewId: 'ga:61943528',
          currentDate,
          setCurrentDate,
          // siteName,
          links,
          getAllLinks
        }}
      >
        <Details
          partner={partner}
          tabs={[
            {
              title: 'Bedrijfsprofiel',
              metrics: ['totalEvents'],
              dimensions: ['eventLabel']
            },
            {
              title: 'Boten',
              metrics: ['totalEvents'],
              dimensions: ['eventAction'],
              showDetails: true
            }
          ]}
        />
      </StatsContext.Provider>
    </Grid>
  </Grid>
}

export default SingleDetailView
