import React from 'react'
import { useData } from 'react-use-analytics-api'
import { AnalyticsContext } from '../components/context'
import { StatsContext } from '../components/stats-tab'
import { format } from 'date-fns'

const getTotalsFromResponse = (response) => {
  const rows = response?.rows
  if (rows) {
    const totalsFromRows = rows.map(totals => {
      return Number(totals[1])
    })
    .reduce((a, b) => a + b, 0)

    return totalsFromRows
  }
  return null
}



const responseDataToRows = (response) => {
  const { rows } = response

  if (rows && rows.length > 0) {
    const filteredResponse = rows
      .map(respData => {
        return {
          id: respData[0],
          name: respData[0],
          views: Number(respData[1])
        }
      })

    return filteredResponse
  }
  return null
}


const useRawAnalyticsData = ({
  metrics,
  dimensions,
  filters
}) => {

  const { gapi } = React.useContext(AnalyticsContext)
  const { viewId, currentDate } = React.useContext(StatsContext)
  const [rawData, setRawData] = React.useState()
  const [totals, setTotals] = React.useState(0)
  const [rowsDataObject, setRowsDataObject] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const query = {
    metrics: metrics,
    dimensions: dimensions,
    'start-date': format(currentDate[0], 'yyyy-MM-dd'),
    'end-date': format(currentDate[1], 'yyyy-MM-dd'),
    ids: viewId,
    filters: filters
  }

  const execute = useData(gapi, query, (response) => {
    setLoading(true)
    setTotals(getTotalsFromResponse(response))
    setRowsDataObject(responseDataToRows(response))
    setRawData(response)
    setLoading(false)
  })

  React.useEffect(() => {
    execute()
  }, [execute])

  return {
    data: rawData,
    rowsDataObject,
    totals,
    execute,
    loading
  }
}

export default useRawAnalyticsData