import React from 'react'

import useRawAnalyticsData from '../hooks/use-raw-analytics-data'
import { StatsContext } from './stats-tab'

import DetailsDataGrid from './details-data-grid'
import { convertDataNameToNiceName } from '../helpers/name'

const addedGaToStrings = (arrayToConvert) => {
  return arrayToConvert
    .map(arr => `ga:${arr}`)
    .join(',')
}

const CustomStatsTab = ({
  metrics,
  dimensions,
  selectedPartner,
  showDetails
}) => {

  const { currentDate, links } = React.useContext(StatsContext)
  const match = selectedPartner.match(/^(\d+)\s*-/)
  let partnerName = convertDataNameToNiceName(selectedPartner)

  if (match && links) {
    links.forEach(link => {
      if (link.object_id === match[1]) {
        partnerName = link.connector
      }
    })
  }

  const rawData = useRawAnalyticsData({
    filters: `ga:eventCategory==${partnerName}`,
    metrics: addedGaToStrings(metrics),
    dimensions: addedGaToStrings(dimensions),
    startDate: currentDate
  })

  React.useEffect(() => {
    if (rawData?.execute) {
      rawData.execute()
    }
  }, [currentDate])


  return <DetailsDataGrid
    showDetails={showDetails}
    metrics={addedGaToStrings(metrics)}
    dimensions={addedGaToStrings(dimensions)}
    selectedPartner={partnerName}
    rows={rawData?.rowsDataObject || []}
  />
}

export default CustomStatsTab

