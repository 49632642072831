import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { autocompleteClasses } from '@material-ui/core/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import Popper from '@material-ui/core/Popper';
import { useTheme, styled } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@material-ui/core/Typography';

const LISTBOX_PADDING = 12; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];

  return (
    <Typography
      component="li"
      {...dataSet[0]}
      noWrap
      style={{
        ...style,
        top: style.top + LISTBOX_PADDING,
      }}
    >
      {dataSet[1].name}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};



const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});


export default function Virtualize({
  options,
  onChange,
  value
}) {
  return (
    <Autocomplete
      id="virtualize-list"
      sx={{ width: 300, marginTop: '30px' }}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={options}
      value={value}
      onChange={(e, value) => {
        onChange(value)
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField
        {...params}
        InputLabelProps={{
          sx: {
            lineHeight: '16px',
            top: '-3px'
          }
        }}
        label="Selecteer partner"
      />}
      renderOption={(props, option) => [props, option]}
      renderGroup={(params) => params}
    />
  );
}


