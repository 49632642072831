import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    height: '80%',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    overflow: 'scroll'
  },
}))

const DataModal = ({
  closeModal,
  children
}) => {
  const classes = useStyles();

  const handleClose = () => {
    closeModal(null)
  }

  return <Modal
    open={true}
    onClose={handleClose}
  >
    <div className={classes.paper}>
      {
        children
      }
    </div>
  </Modal>
}

DataModal.propTypes = {
  children: PropTypes.any.isRequired,
  closeModal: PropTypes.func.isRequired
}

export default DataModal
