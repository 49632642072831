import './App.css';
import React from 'react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'
import Analytics from './components/analytics'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import SingleDetailView from './components/single-detail-view'
import Context from './components/context'

const theme = createTheme();

const getToken = async () => {
  const tokenResponse = await fetch('https://www.mijnhiswarecron.nl/site/google/access-token')
  const toJson = await tokenResponse.json()
  return toJson
}

const useGetToken = () => {
  const [token, setToken] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    const fetchToken = async () => {
      setLoading(true)

      try {
        const token = await getToken()
        setToken(token)
      } catch (e) {
        setError(e)
      }

      setLoading(false)
    }

    fetchToken()
  }, [])

  return {
    token,
    loading,
    error
  }
}

function App() {
  const { token, error, loading } = useGetToken()

  if (error) {
    return null
  }

  if (!token) {
    return null
  }
  
  const searchParam = new URL(document.location).searchParams
  const partnerParam = searchParam.get('partner')
  const viewIds = searchParam.get('viewIds')

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Context token={token} viewIds={viewIds}>
          {
            !loading
              ? partnerParam
                ? <SingleDetailView partner={partnerParam} />
                : <Analytics token={token} />
              : <Grid container item xs={12} justify='center'>
                <Box mt={2}>
                  <CircularProgress />
                </Box>
              </Grid>
          }
        </Context>
      </div>
    </ThemeProvider>
  )
}

export default App;
