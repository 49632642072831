import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    flexGrow: 1
  }
}))

const PaperBox = ({ paperProps, children }) => {
  const classes = useStyles()
  return <Paper className={classes.paper} {...paperProps}>
    { children }
  </Paper>
}

PaperBox.propTypes = {
  children: PropTypes.any
}

export default PaperBox