import React from 'react'
import Grid from '@material-ui/core/Grid'
import { AnalyticsContext } from './context'

import { Typography, Link } from '@material-ui/core'
import StatsTab from './stats-tab'
import { Box, Tabs, Tab, Container } from '@material-ui/core'

const views = {
  'ga:61943528': 'Vakantievaren',
  'ga:41179689': 'Hiswa'
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const getAllLinks = async () => {
  const allLinkResponse = await fetch('https://www.mijnhiswarecron.nl/myhiswa/endpoint/my/analytics-link')
  const allLinkJson = await allLinkResponse.json()

  return allLinkJson.data
}

const Analytics = () => {
  const { viewIds } = React.useContext(AnalyticsContext)
  const [currentTab, setCurrentTab] = React.useState(0)
  const [links, setLinks] = React.useState([])

  const viewIdsSplit = viewIds?.split(',')

  const fetchLinks = async () => {
    const links = await getAllLinks()
    setLinks(links)
  }

  React.useEffect(() => {
    fetchLinks()
  }, [])

  return <Container maxWidth='md'>
    <Grid container>
      <Grid item xs={12}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h5'>
            Analytics
            {viewIdsSplit?.length === 1 ? ' ' + views[viewIdsSplit[0]] : ''}
          </Typography>
        </Box>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      {
        viewIdsSplit?.length > 0
          ? viewIdsSplit?.length === 1
            ? <StatsTab
                viewId={viewIdsSplit[0]}
                siteName={(viewIdsSplit[0] in views) ? views[viewIdsSplit[0]] : viewIdsSplit[0]}
                getAllLinks={fetchLinks}
                links={links.filter(link => link.site === views[viewIdsSplit[0]])}
              />
            : <>
              <Tabs
                value={currentTab}
                onChange={(e, newTab) => setCurrentTab(newTab)}
              >
                {
                  viewIdsSplit.map(view => <Tab label={(view in views) ? views[view] : view} />)
                }
              </Tabs>
              {
                viewIdsSplit.map((view, index) =>
                <TabPanel value={currentTab} index={index}>
                  <StatsTab
                    viewId={view}
                    siteName={(view in views) ? views[view] : view}
                    getAllLinks={fetchLinks}
                    links={links.filter(link => link.site === views[view])}
                  />
                </TabPanel>)
              }
            </>
          : <>
          <Tabs
            value={currentTab}
            onChange={(e, newTab) => setCurrentTab(newTab)}
          >
            {Object.keys(views).map(key => <Tab key={key} label={views[key]} />)}
          </Tabs>
          {Object.keys(views).map((key,index) => {
            return <TabPanel value={currentTab} index={index}>
              <StatsTab
                viewId={key}
                siteName={views[key]}
                getAllLinks={fetchLinks}
                links={links.filter(link => link.site === views[key])}
              />
            </TabPanel>
          })}
        </>
      }
    </Grid>
  </Container>
}


export default Analytics
