import React from 'react'
import PropTypes from 'prop-types'
import { AnalyticsContext } from './context'
import { useData } from 'react-use-analytics-api'
import { DataGrid } from '@material-ui/data-grid'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import DataModal from './data-modal'
import Details from './details'
import { StatsContext } from './stats-tab'
import DateRange from './date-range'
import { makeStyles } from '@material-ui/styles'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import AutocompleteVirtualize from './autocomplete-virtualized'
import StaticDateRange from './static-date-range'
import { format } from 'date-fns'

const doCallback = async (selectedValue, { fullName }, siteName) => {
  const response = await fetch('https://www.mijnhiswarecron.nl/myhiswa/endpoint/my/analytics-link', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: selectedValue?.value || null,
      connector: fullName,
      site: siteName
    })
  })
  const responseJson = response.json()
  return responseJson
}

const VirtualizedCell = ({ value, row, options, siteName }) => {
  const { getAllLinks } = React.useContext(StatsContext)
  const [currentValue, setCurrentValue] = React.useState(value)

  const setNewValue = async (selectedValue) => {
    if (!selectedValue) {
      setCurrentValue(null)
    }
    await doCallback(selectedValue, row, siteName)
    await getAllLinks()
  }

  return <AutocompleteVirtualize
    onChange={setNewValue}
    options={options}
    value={currentValue}
  />
}

const columns = ({ showConnect, partners, siteName, links }) => {
  const baseColumn = [
    {
      field: 'name',
      headerName: 'Partner',
      flex: .5,
      filterable: false
    },
    {
      field: 'views',
      headerName: 'Views',
      align: 'right',
      headerAlign: 'right',
      flex: .25,
      filterable: false
    }
  ]

  if (showConnect) {
    baseColumn.push({
      field: 'connect',
      align: 'right',
      headerAlign: 'right',
      headerName: 'Partner verbinden',
      renderCell: (...rest) => {
        const { row } = rest[0]

        const connector = links?.find(link => link.connector === row.name)
        const currentValue = connector && partners.find(partner => partner.value === connector.object_id)

        return <VirtualizedCell
          options={partners.sort((a, b) => a.name.localeCompare(b.name))}
          value={currentValue}
          row={row}
          siteName={siteName}
        />
      },
      flex: .5,
      filterable: false,
      sortable: false
    })
  }

  return baseColumn
}
/*
  metrics='ga:totalEvents'
  dimensions='ga:eventCategory'
*/

const FORBIDDEN_NAMES = [
  'uitgaande-link-externe-site',
  'Formulier versturen'
]

const DataTable = ({ metrics, dimensions }) => {
  const { gapi, token } = React.useContext(AnalyticsContext)
  const { currentDate, viewId, siteName, links } = React.useContext(StatsContext)
  const [rows, setRows] = React.useState()
  const [pageSize, setPageSize] = React.useState(10)
  const [selectedPartner, setSelectedPartner] = React.useState(false)
  const [filter, setFilter] = React.useState('')
  const [showConnect, setShowConnector] = React.useState(false)
  const [partners, setPartners] = React.useState([])

  React.useEffect(() => {
    const getAllPartners = async () => {
      const partners = await fetch('https://www.mijnhiswarecron.nl/myhiswa/endpoint/my/profiles')
      const partnersJson = await partners.json()
      const partnerArray = partnersJson?.data && Object.keys(partnersJson?.data).map((key) => {
        return {
          value: `${key}`,
          name: partnersJson?.data[key]
        }
      })

      setPartners(partnerArray)
    }

    getAllPartners()
  }, [])

  const query = {
    metrics: metrics,
    dimensions: dimensions,
    'start-date': format(currentDate[0], 'yyyy-MM-dd'),
    'end-date': format(currentDate[1], 'yyyy-MM-dd'),
    ids: viewId,
  }

  const execute = useData(gapi, query, (response) => {
    const { rows } = response
    if (rows && rows.length > 0) {
      const filteredResponse = rows
        .map((respData, index) => {
          const splitName = respData[0].split(' - ')

          return {
            id: index,
            itemIndex: splitName[0],
            name: respData[0],
            fullName: respData[0],
            views: Number(respData[1]),
            connect: null
          }
        })
        .filter(rowData => FORBIDDEN_NAMES.indexOf(rowData.name) === -1)

      setRows(filteredResponse)
    }
  })

  const onRowClick = (param) => {
    const { row: { fullName } } = param
    setSelectedPartner(fullName)
  }

  const closeModal = () => setSelectedPartner(null)

  React.useEffect(() => {
    execute()
  }, [execute, currentDate])

  if (!rows) {
    return null
  }

  const filteredRows = rows.filter(row => {
    const lowerName = row.name.toLowerCase()
    const lowerfilter = filter.toLowerCase()

    if (lowerfilter.length > 0) {
      return lowerName.includes(lowerfilter)
    }

    return true
  })

  const makeColumns = columns({ showConnect, partners, siteName, links })

  return <div>
    <Box
      mb={2}
      display='flex'
      alignItems='center' justifyContent='space-between'
    >
      <Box>
        <TextField
          onChange={(e) => setFilter(e.target.value)}
          label='Zoeken'
          variant='outlined'
          size='small'
          value={filter}
        />
      {
        filter.length > 0  && <a style={{ marginLeft: 10 }} onClick={() => setFilter('')}>Wissen</a>
      }
      </Box>
      <Box
        display='flex'
        flexGrow={1}
      />
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={showConnect} />}
          onChange={(e) => setShowConnector(e.target.checked)}
          label='Toon "link partner"-veld'
        />
      </FormGroup>
      {/* <DateRange /> */}
      <StaticDateRange />
    </Box>

    <DataGrid
      rows={filteredRows}
      columns={makeColumns}
      rowHeight={67}
      pageSize={pageSize}
      autoHeight
      rowsPerPageOptions={[10, 20, 50]}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      disableSelectionOnClick
      onCellClick={(cell, e) => {
        if (cell.field !== 'connect') {
          return onRowClick(cell)
        }
      }}
      disableColumnFilter
      disableColumnMenu
      sortModel={[
        {
          field: 'views',
          sort: 'desc'
        }
      ]}
    />
    {
      selectedPartner &&
        <DataModal
          selectedPartner={selectedPartner}
          closeModal={closeModal}
        >
          <Details
            partner={selectedPartner}
            tabs={[
              {
                title: 'Bedrijfsprofiel',
                metrics: ['totalEvents'],
                dimensions: ['eventLabel']
              },
              {
                title: 'Boten',
                metrics: ['totalEvents'],
                dimensions: ['eventAction'],
                showDetails: true
              }
            ]}
          />
        </DataModal>
    }
  </div>
}

DataTable.propTypes = {
  metrics: PropTypes.string.isRequired,
  dimensions: PropTypes.string.isRequired
}

export default DataTable
