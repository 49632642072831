import React from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { styled } from '@material-ui/system'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import SidePanelDetails from './side-panel-details'

const columns = [
  {
    field: 'name',
    headerName: 'Actie',
    flex: 1,
    filterable: false,
    sortable: false
  },
  {
    field: 'views',
    headerName: 'Gebeurtenissen',
    flex: .5,
    filterable: false,
    sortable: false
  }
]

const drawerWidth = 380

const opened = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerWidth
})

const closed = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0
})

const SidePanel = styled('div')(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...opened(theme)
  }),
  ...(!open && {
    ...closed(theme)
  })
}))

const DetailsDataGrid = ({
  rows,
  showDetails,
  metrics,
  dimensions,
  selectedPartner
}) => {
  const [open, setOpen] = React.useState(false)
  const [selectedDetail, setSelectedDetail] = React.useState('')

  const onRowClick = (rowData) => {
    if (!open) {
      setOpen(!open)
    }
    setSelectedDetail(rowData?.row?.name)
  }

  return <div>
    <Box
      sx={{
        display: 'flex'
      }}
    >
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          height: 500,
          width: '100%'
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={50}
          disableMultipleSelection
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          onRowClick={onRowClick}
          sortModel={[
            {
              field: 'views',
              sort: 'desc'
            }
          ]}
        />
      </Box>
      {
        showDetails && <SidePanel
          open={open}
        >
          <Box
            sx={{
              px: '20px'
            }}
          >
            <SidePanelDetails
              setOpen={() => setOpen(false)}
              metrics={metrics}
              dimensions={dimensions}
              selectedPartner={selectedPartner}
              selectedDetail={selectedDetail}
            />
          </Box>
        </SidePanel>
      }
    </Box>
  </div>
}

export default DetailsDataGrid